import MusicPlayer from "./components/MusicPlayer";
import Home from "./pages/Home";
import Private from "./pages/Private";

export default class Main {
  constructor() {

    if (document.body.hasAttribute('data-page')) {
      const page = document.body.dataset.page;

      switch(page) {
        case 'home':
          this._page = new Home();
          break;
        case 'private':
          this._page = new Private();
          break;
        default:
          break;
      }
    }

    this.init();
  }

  init() {
    document.documentElement.classList.remove("no-js");

    if (this._page) this._page.init();

    this.onResize();
    window.addEventListener("resize", this.onResize.bind(this));

    document.body.classList.add("loaded");
  }

  initMusicPlayer() {
    this._player = new MusicPlayer();
  }

  _calculateScrollbarWidth() {
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      window.innerWidth - document.documentElement.clientWidth + "px"
    );
  }

  //-----------------------------------------------------o public update
  update() {
    if (this._page) this._page.update();
  }

  resize() {
    this._calculateScrollbarWidth();
  }

  onResize() {
    this.resize();
    if (this._page) this._page.resize();
  }
}
