/**
 * Default list of supported emojis.
 */
export const defaultEmojis = [
	"😁",
	"😂",
	"🤣",
	"😃",
	"😆",
	"😍",
	"🤩",
	"😎",
	"🤗",
	"🥳",
	"🤖",
	"😻",
	"😹",
	"🐱",
	"🐶",
	"🙈",
	"🙉",
	"🙊",
	"🏄",
	"💪",
	"👌",
	"👋",
	"🙌",
	"🫶",
	"💝",
	"💖",
	"💗",
	"🧡",
	"💛",
	"💚",
	"💙",
	"💜",
	"❤️‍🔥",
	"🔥",
	"🚀",
	"⛄",
	"🦩",
	"🍍",
	"💐",
	"🦾",
	"✨",
	"🎉",
	"💯",
];
