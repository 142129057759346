import Amplitude from "amplitudejs";

export default class MusicPlayer {
  constructor($element) {
    this.$element = $element;

    Amplitude.init({
      songs: [
        {
          name: "Alfie",
          artist: "Dionne Warwick",
          url: "/mp3/01 - Dionne Warwick - Alfie.mp3",
        },
        {
          name: "Alfie's Theme",
          artist: "Sonny Rollins",
          url: "/mp3/02 - Sonny Rollins - Alfie's Theme.mp3",
        },
        {
          name: "Becoming More Like Alfie",
          artist: "The Divine Comedy",
          url: "/mp3/03 - The Divine Comedy - Becoming More Like Alfie.mp3",
        },
        {
          name: "Alfons Åberg signatur - Alternativ Version",
          artist: "Georg Riedel",
          url: "/mp3/04 - Alfons Åberg - Alfons Åberg signatur - Alternativ Version.mp3",
        },
        {
          name: "He's Younger than You Are",
          artist: "Sonny Rollins",
          url: "/mp3/05 - Sonny Rollins - He's Younger than You Are.mp3",
        },
        {
          name: "Alfie",
          artist: "Rahsaan Roland Kirk",
          url: "/mp3/06 - Rahsaan Roland Kirk - Alfie.mp3",
        },
        {
          name: "Alifib",
          artist: "Robert Wyatt",
          url: "/mp3/07 - Robert Wyatt - Alifib.mp3",
        },
        {
          name: "Blues for Alfy",
          artist: "Count Basie",
          url: "/mp3/08 - Count Basie - Blues for Alfy.mp3",
        },
        {
          name: "Alfie",
          artist: "Barney Kessel",
          url: "/mp3/09 - Barney Kessel - Alfie.mp3",
        },
        {
          name: "Alifib",
          artist: "Pascal Comelade",
          url: "/mp3/10 - Pascal Comelade - Alifib.mp3",
        },
        {
          name: "Alfie",
          artist: "Raymond Berthiaume",
          url: "/mp3/11 - Raymond Berthiaume - Alfie.mp3",
        },
        {
          name: "All the Pretty Little Horses",
          artist: "Alfred Deller",
          url: "/mp3/12 - Alfred Deller - All the Pretty Little Horses.mp3",
        },
        {
          name: "Salade de fruits",
          artist: "Enzo Enzo",
          url: "/mp3/13 - Enzo Enzo - Salade de fruits.mp3",
        },
        {
          name: "Bébé éléphant",
          artist: "Dick Annegarn",
          url: "/mp3/14 - Dick Annegarn - Bébé éléphant.mp3",
        },
        {
          name: "Alfie's Theme",
          artist: "The Thing",
          url: "/mp3/15 - The Thing - Alfie's Theme.mp3",
        },
        {
          name: "Asu Naki Bousou no Hate ni",
          artist: "THE ALFEE",
          url: "/mp3/16 - THE ALFEE - Asu Naki Bousou no Hate ni.mp3",
        },
        {
          name: "Stuck on Earth",
          artist: "Alf",
          url: "/mp3/17 - Alf - Stuck on Earth.mp3",
        },
        {
          name: "Alfie",
          artist: "Lily Allen",
          url: "/mp3/18 - Lily Allen - Alfie.mp3",
        },
        {
          name: "Pity Poor Alfie / Fever",
          artist: "The Jam",
          url: "/mp3/19 - The Jam - Pity Poor Alfie--Fever.mp3",
        },
        {
          name: "Alfie",
          artist: "Cher",
          url: "/mp3/20 - Cher - Alfie.mp3",
        },
      ],
    });

    this.$progress = this.$element
      .querySelector(".amplitude-song-played-progress")
      .addEventListener("click", function (e) {
        var offset = this.getBoundingClientRect();
        var x = e.pageX - offset.left;

        Amplitude.setSongPlayedPercentage(
          (parseFloat(x) / parseFloat(this.offsetWidth)) * 100
        );
      });
  }
}
