import Main from './Main';

//-----------------------------------------------------o
//-------------------------------o READY
//-----------------------------------------------------o
document.addEventListener('DOMContentLoaded', function () {
	let main = new Main();

	(function tick() {
		main.update();
		window.requestAnimationFrame(tick);
	})();
});
