import MusicPlayer from "../components/MusicPlayer";
import Page from "./Page";
import simpleParallax from '../vendor/simpleParallax';

export default class Private extends Page {
  constructor() {
    super(...arguments);
  }

  init() {
    this._player = new MusicPlayer(document.querySelector(".js-music-player"));

    this._scrollTops = document.querySelectorAll(".js-scroll-top");

    this._scrollTops.forEach((obj) => {
      obj.addEventListener(
        "click",
        function (e) {
          let id = document.getElementById('top');
          id.scrollIntoView({
            behavior: "smooth",
          });
          e.preventDefault();
          return false;
        }.bind(this)
      );
    });

    const photosParallax = new simpleParallax(document.querySelectorAll('.photos picture img'), {
      orientation: 'left right',
      scale: 1.25,
      overflow: true,
    });

    const titlesParallax = new simpleParallax(document.querySelectorAll('.photos__month--title'), {
      orientation: 'up left',
      scale: 1.15,
      overflow: true,
    });

  }

  //-----------------------------------------------------o public update
  update() {}

  resize() {}
}
